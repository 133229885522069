<template>
  <div>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-toggle.sidebar-right
      variant="outline-primary"
    >
      ตั้งค่าร้าน
    </b-button>
    <b-sidebar
      id="sidebar-right"
      bg-variant="white"
      v-model="sidebar"
      right
      backdrop
      shadow
    >
      <div v-for="store in data_stores" :key="store.id">
        <div class="text-center">
          <!-- Avatar -->
          <b-avatar size="72px" class="mr-1" :src="logo_oa" />

          <!-- Name -->
          <b-card-text class="mt-2 h4 color-inherit text-reset">
            แก้ไขข้อมูล
          </b-card-text>
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      v-model="name"
                      placeholder="ชื่อร้านค้าใหม่"
                    />
                  </b-input-group>
                  <b-alert v-if="check.name == true" variant="danger" show>
                    <span>Please Enter</span>
                  </b-alert>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <b-input-group class="input-group-merge">
                    <b-form-select
                      v-model="selected"
                      :options="options"
                      :state="selected === null ? false : true"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <b-input-group class="input-group-merge">
                    <b-form-select
                      v-model="selected2"
                      :options="options2"
                      :state="selected2 === null ? false : true"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!-- <b-col cols="12">
                <b-form-group>
                  <b-input-group class="input-group-merge">
                    <b-form-file
                      v-model="file"
                      placeholder="เปลี่ยนรูป"
                      drop-placeholder="Drop file here..."
                      type="file"
                      accept=".jpeg,.png,.jpg,GIF"
                      :hidden="true"
                      @change="onFileChange"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col> -->

              <!-- submit and reset -->
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  block
                  class="mr-1"
                  @click="update_store()"
                >
                  บันทึก
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BButton,
  BSidebar,
  VBToggle,
  BAvatar,
  BListGroup,
  BListGroupItem,
  BCardText,
  BCard,
  BCardHeader,
  BMedia,
  BMediaAside,
  BMediaBody,
  BBadge,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BInputGroup,
  BFormSelect,
  BAlert,
  BFormFile,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";
export default {
  components: {
    BButton,
    BSidebar,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BCard,
    BCardHeader,
    BMedia,
    BMediaAside,
    BMediaBody,
    BBadge,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BInputGroup,
    BFormSelect,
    BAlert,
    BFormFile,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      sidebar: false,
      data_stores: "",
      totalRows: "",
      options: [],
      options2: [],
      selected: null,
      selected2: null,
      name: null,
      code: null,
      file: null,
      profile: null,
      id: null,
      check: {
        name: false,
      },
      logo_oa:localStorage.getItem('logo'),
    };
  },
  watch: {
    selected: function (val) {
           this.options2= [],
      this.loadtype_sub(val);
    },
  },
  mounted() {
    this.logo_oa =localStorage.getItem('logo'),
    this.name_user = JSON.parse(localStorage.getItem("userData"));
    this.loaddata_store_id();
    this.loaddata_brach_inby_store();
  },
  methods: {
    refresh() {
      this.$emit("loaddata_store_id", "loaddata_store_id");
    },
    onFileChange(e) {
      this.file = e.target.files[0];
      console.log("file", this.file);
      const image = e.target.files[0];
      this.profile = URL.createObjectURL(image); ///show
    },
    async loaddata_store_id() {
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      api
        .post("getstore_id", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.data_stores = response.data.data;
          this.totalRows = response.data.total;
          for (let item of response.data.data) {
            this.name = item.store_name;
            this.id = item.id;
            this.code = item.code;
            this.selected = item.type_id;
            this.selected2 = item.type_sub_id;
            this.profile = item.pictrue;
            this.loadtype_sub(this.selected);
          }
        })
        .catch((error) => {
          // window.location.reload();
        });

      api
        .post("business_type", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.options = response.data.data;
        })
        .catch((error) => {});
    },
    async loadtype_sub(id) {
      const params = {
        id: id,
      };
      api
        .post("type_sub", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.options2 = response.data.type_sub;
            this.selected2 = response.data.type_sub[0].value;
        })
        .catch((error) => {});
    },

    update_store(id) {
      this.sidebar = true;
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("store_name", this.name);
      formData.append("code", this.code);
      formData.append("business_type", this.selected);
      formData.append("type_sub", this.selected2);
      formData.append("id", this.id);
      formData.append("profile", this.logo_oa);
      // console.log(this.file);
      // console.log(this.name);
      // console.log(this.code);
      // console.log(this.selected);
      // console.log(this.selected2);
      // console.log(this.profile);
      // console.log(this.id);
      if (this.name) {
        this.$swal({
          title: "คุณต้องการแก้ไขข้อมูลร้าน ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "SAVE",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            api
              .post("/updatestore", formData, {
                headers: {
                  Authorization:
                    "Bearer " + localStorage.getItem("accessToken"),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                if (response) {
                  this.$swal({
                    icon: "success",
                    title: "success",
                    showConfirmButton: false,
                    timer: 3000,
                  });
                  // this.loaddata();
                  localStorage.setItem("store_id", this.id);
                  localStorage.setItem("store_name", this.name);
                  this.check.name = false;
                  this.name = "";
                  this.code = "";
                  this.file = "";
                  this.profile = "";
                  this.sidebar = false;
                  this.$forceUpdate();
                  this.loaddata_store_id();
                  this.refresh();
                }
              });
          }
        });
      } else {
        this.check.name = true;
        this.$swal({
          title: "Error!",
          text: " ตรวจสอบข้อมูลใหม่อีกครั้ง!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
  },
};
</script>
 